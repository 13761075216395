import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { graphql } from 'gatsby';
import isEmpty from 'lodash/fp/isEmpty';
import getOr from 'lodash/fp/getOr';
import Link from '../../atoms/Link/Link';
import Layout from '../../organisms/Layout/Layout';
import Section from '../../molecules/Section/Section';
import PageWrapper from '../../organisms/PageWrapper/PageWrapper';
import {
  breakpoints,
  colors,
  fontSizes,
  unitConverter as uc,
} from '../../../styles/base';
import { renderPageSections } from '../renderPageSections';
import Text from '../../atoms/Text/Text';
import useLocalize from '../../../hooks/useLocalize';
import Heading1 from '../../atoms/Headings/Heading1/Heading1';

const sectionCss = css`
  margin: ${uc('0px 20px 20px 20px')};

  @media (${breakpoints.largeTablet}) {
    margin: ${uc('0px 40px 20px 40px')};
  }
`;

const headingCss = css`
  ${sectionCss}
  color: ${colors.black};
  font-size: ${fontSizes.thirtyEight};
  letter-spacing: 1px;

  @media (${breakpoints.tablet}) {
    font-size: ${fontSizes.thirtyTwo};
  }

  @media (${breakpoints.mobile}) {
    font-size: ${fontSizes.twentyEight};
  }
`;

const seeAlsoSectionCSS = css`
  ${sectionCss}
  padding: ${uc('20px')};
  border: solid 1px ${colors.darkGray.one};

  ul {
    list-style-type: none;

    li {
      padding-top: ${uc('5px')};
    }
  }
`;

const containerCss = css`
  margin-top: ${uc('150px')};
  margin-bottom: ${uc('50px')};

  @media (${breakpoints.tablet}) {
    margin-top: ${uc('180px')};
    margin-bottom: ${uc('60px')};
  }
`;

const blockContentWrapperCss = css`
  @media (${breakpoints.largeTablet}) {
    margin: ${uc('-40px 0px')};
  }
`;

const GlossaryTermPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  if (!rawData) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const glossaryTermPageProps = {
    id: data.sanityGlossaryTermPage._id,
    metadata: { ...data.sanityGlossaryTermPage._rawRoute, href },
    sections: data.sanityGlossaryTermPage._rawSections,
  };

  const relatedGlossaryTerms = getOr(
    [],
    'allSanityGlossaryTermPage.edges',
    data
  ).map(edge => ({
    name: edge.node.name,
    slug: edge.node.route.slug.current,
  }));

  return (
    <Layout
      locale={locale}
      menuColor="primary"
      sanityId={glossaryTermPageProps.id}
      {...glossaryTermPageProps.metadata}
    >
      <PageWrapper>
        <div css={containerCss}>
          <Section>
            <Heading1 css={headingCss}>
              {data.sanityGlossaryTermPage.name}
            </Heading1>
            <div css={blockContentWrapperCss}>
              {renderPageSections(glossaryTermPageProps.sections)}
            </div>

            <div css={sectionCss}>
              <Link to={data.sanityGlossaryTermPage.relatedLink}>
                {data.sanityGlossaryTermPage.relatedLink.text}
              </Link>
            </div>

            {!isEmpty(relatedGlossaryTerms) && (
              <div css={seeAlsoSectionCSS}>
                <Text>See Also:</Text>
                <ul>
                  {relatedGlossaryTerms.map(({ name, slug }) => (
                    <li key={slug}>
                      <Link to={slug}>{name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div css={sectionCss}>
              <Link to="/glossary">Back to glossary</Link>
            </div>
          </Section>
        </div>
      </PageWrapper>
    </Layout>
  );
};

GlossaryTermPage.propTypes = {
  data: PropTypes.shape({ sanityGlossaryTermPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

GlossaryTermPage.defaultProps = {
  data: null,
  location: { href: '' },
  pageContext: { locale: '' },
};

export const query = graphql`
  query($slug: String, $tags: [String]) {
    sanityGlossaryTermPage(route: { slug: { current: { eq: $slug } } }) {
      _id
      name: _rawName(resolveReferences: { maxDepth: 100 })
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      relatedLink {
        link {
          ... on SanityExternalLink {
            externalLink: _rawExternalLink(resolveReferences: { maxDepth: 100 })
            _key
          }
          ... on SanityRoute {
            slug {
              current
            }
            _type
          }
        }
        text: _rawText(resolveReferences: { maxDepth: 100 })
      }
    }
    allSanityGlossaryTermPage(
      filter: {
        route: { slug: { current: { ne: $slug } } }
        productType: { in: $tags }
      }
      limit: 10
    ) {
      edges {
        node {
          name: _rawName(resolveReferences: { maxDepth: 100 })
          route {
            slug {
              current
            }
          }
        }
      }
    }
  }
`;

export default GlossaryTermPage;
